import React from "react"; 
import logo from "../img/erdogan-logo.svg"

import "./index.css"
import { Container, Row, Col } from "react-bootstrap";
import { contactConfig } from "../content_options"
import { FaBeer } from "react-icons/fa";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import Img01 from "../img/01-min.webp";
import Img02 from "../img/02-min.webp";
import Img03 from "../img/03-min.webp";

export default function ContactUs() {
  

  return (
    <>
<nav class="navbar bg-light navbar-head">
  <div class="container-fluid navbar-child">
    <a class="navbar-brand" href="#">
      <img src={logo} alt="Logo" width="300" height="90" class="d-inline-block align-text-top"/>
    </a>
  </div>
</nav>

    <div className="container-fluid container-head">
    {/*<img src={logo} class="img-fluid rounded mx-auto d-block logo"  alt="..."></img>*/}
    
    <div id="carouselExampleIndicators" className="carousel slide mb-3" data-bs-ride="carousel" >
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="2000">
      <img src={Img01} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={Img02} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={Img03} className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span classNames="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>


    <div className="row">
      <div className="col-12 col-md-4 p-1">
    <div className="card text-bg-success mb-3 " >
      <div className="card-header">Adres</div>
      <div className="card-body">
        <h5 className="card-title">Yol Tarifi al</h5>
        <p className="card-text"><a className="text-decoration-none" href="https://maps.app.goo.gl/UqESKjkzimCPZAhf6">Zühtüpaşa, Bağdat Cad. No:6, 34724 Kadıköy/İstanbul</a></p>
      </div>
    </div>
</div>

<div className="col-12 col-md-4 p-1">
    <div className="card text-bg-success mb-3">
      <div className="card-header">Telefon</div>
      <div className="card-body">
        <h5 className="card-title">Bize Ulaşın</h5>
        <p className="card-text"><a className="text-decoration-none" href="tel:+902163485365">(0216) 348 53 65</a></p>
        <p className="card-text"><a className="text-decoration-none" href="tel:+902163488225">(0216) 348 82 25</a></p>
        <p className="card-text"><a className="text-decoration-none" href="tel:+905427142457">(0542) 714 24 57</a></p>
      </div>
    </div>
</div>
<div className="col-12 col-md-4 p-1">
    <div className="card text-bg-success mb-3" >
      <div className="card-header">Eposta adresimiz</div>
      <div className="card-body">
        <h5 className="card-title">Bize yazın</h5>
        <p className="card-text"><a className="text-decoration-none" href="mailto:info@erdogancicekcilik.com">info@erdogancicekcilik.com</a></p>
      </div>
    </div>
</div></div>
    </div>
      {/*
      <Container>     
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Contact Me</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5" className="mb-5">
            <h3 className="color_sec py-4">Get in touch</h3>
            <address>
              <strong>Email:</strong>{" "}
              <a href={`mailto:${contactConfig.YOUR_EMAIL}`}>
                {contactConfig.YOUR_EMAIL}
              </a>
              <br />
              <br />
              {contactConfig.hasOwnProperty("YOUR_FONE") ? (
                <p>
                  <strong>Phone:</strong> {contactConfig.YOUR_FONE}
                </p>
              ) : (
                ""
              )}
            </address>
            <p>{contactConfig.description}</p>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <form  className="contact__form w-100">
              <Row>
                <Col lg="6" className="form-group">
                    <FaBeer/>
                  <input
                    className="form-control white"
                    id="name"
                    name="name"
                    placeholder="İsim" 
                    type="text"
                    required 
                  />
                </Col>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control rounded-0"
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email" 
                    required 
                  />
                </Col>
              </Row>
              <textarea
                className="form-control rounded-0"
                id="message"
                name="message"
                placeholder="Message"
                rows="5" 
                required
              ></textarea>
              <br />
              <Row>
                <Col lg="12" className="form-group">
                  <button className="btn ac_btn" type="submit"> 
                  Send
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
      */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.7900141343457!2d29.035972177235674!3d40.98607872078968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab87bdc299739%3A0x814d488fc387c87b!2zRXJkb8SfYW4gw4dpw6dla8OnaWxpaw!5e0!3m2!1str!2str!4v1697411058068!5m2!1str!2str" 
      width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </>
  );
}
