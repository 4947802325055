
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";   
import ContactUs from '../contact/index'
import WhatsNullApp from "../components/whatsapp-button-nullus";

export default function App() {
  return (
    <>
     <WhatsNullApp/>
     <ContactUs />
    </>
  );
}